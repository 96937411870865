import { Box } from '@chakra-ui/react'

interface Props {
    title: string
    bgColor: string
}

export const Ruban = (props: Props) => {
    return (
        <Box
            position={'absolute'}
            w={'200px'}
            padding={'5px'}
            textAlign={'center'}
            transform={'rotate(45deg)'}
            top={'24px'}
            right={'-60px'}
            color={'white'}
            bg={props.bgColor}
            fontSize={'14px'}
            zIndex={'100'}
        >
            <b>{props.title}</b>
        </Box>
    )
}
