import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface Props {
    children: ReactNode
}

export const BluredBox = (props: Props) => {
    return (
        <Box
            bg={'#1F293766'}
            backdropFilter={'blur(10px)'}
            h={'100%'}
            padding={'20px'}
            borderRadius={'20px'}
            color={'white'}
        >
            {props.children}
        </Box>
    )
}
