import { Center, Heading, useColorModeValue, Text } from '@chakra-ui/react'
import { useState, ReactNode, Children } from 'react'

interface Props {
    children: ReactNode
    withDecoration?: boolean
}

export const SectionTitle = (props: Props) => {
    return (
        <Center pb="60px">
            <Heading
                color={useColorModeValue('#F1F2F3', '#000')}
                fontSize={{ base: '20px', sm: '24px', md: '30px' }}
            >
                {props.withDecoration && (
                    <Text as="span" color={'#FE7A36'} mr={'10px'}>
                        <b>{'</ '}</b>
                    </Text>
                )}
                {props.children}
                {props.withDecoration && (
                    <Text as="span" color={'#FE7A36'} ml={'10px'}>
                        <b>{' />'}</b>
                    </Text>
                )}
            </Heading>
        </Center>
    )
}
