import { Center, Tooltip } from '@chakra-ui/react'
import { BentoBox } from '../BentoBox'

export const TechnoWP = () => (
    <Tooltip label="WordPress">
        <BentoBox size={'C1'} bgImage={'techno/wp.svg'} bgSize={'90%'} />
    </Tooltip>
)

export const TechnoGIT = () => (
    <Tooltip label="Git">
        <BentoBox size={'C1'} bgImage={'techno/git.svg'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoServerLess = () => (
    <BentoBox size={'C1'}>
        <Center as="b" mt={'-6px'} fontSize={'15px'}>
            Server
        </Center>
        <Center as="b" mt={'4px'} fontSize={'15px'}>
            Less
        </Center>
    </BentoBox>
)
export const TechnoDocker = () => (
    <Tooltip label="Docker">
        <BentoBox size={'C1'} bgImage={'techno/docker.svg'} bgSize={'80%'} />
    </Tooltip>
)
export const TechnoFireBase = () => (
    <Tooltip label="Firebase">
        <BentoBox size={'C1'} bgImage={'techno/firebase.svg'} bgSize={'50%'} />
    </Tooltip>
)
export const TechnoGCP = () => (
    <Tooltip label="Google Cloud Platform">
        <BentoBox size={'C1'} bgImage={'techno/gcp.svg'} bgSize={'80%'} />
    </Tooltip>
)
export const TechnoReact = () => (
    <Tooltip label="React">
        <BentoBox size={'C1'} bgImage={'techno/react.svg'} bgSize={'80%'} />
    </Tooltip>
)
export const TechnoNode = () => (
    <Tooltip label="Node.JS">
        <BentoBox
            size={'C1'}
            bgColor={'#8cc84b'}
            bgImage={'techno/node.svg'}
            bgSize={'fit'}
        />
    </Tooltip>
)
export const TechnoTS = () => (
    <Tooltip label="TypeScript">
        <BentoBox
            size={'C1'}
            bgColor={'#007ACC'}
            bgImage={'techno/ts.svg'}
            bgSize={'fit'}
        />
    </Tooltip>
)
export const TechnoJS = () => (
    <Tooltip label="JavaScript">
        <BentoBox
            size={'C1'}
            bgColor={'#F7DF1C'}
            bgImage={'techno/js.svg'}
            bgSize={'fit'}
        />
    </Tooltip>
)
export const TechnoJava = () => (
    <Tooltip label="Java">
        <BentoBox
            size={'C1'}
            bgColor={'#FFF'}
            bgImage={'techno/java.svg'}
            bgSize={'40%'}
        />
    </Tooltip>
)
export const TechnoAPI = () => (
    <BentoBox size={'C1'}>
        <Center as="b" mt={'3px'} fontSize={'19px'}>
            API
        </Center>
    </BentoBox>
)
export const TechnoMongoDb = () => (
    <Tooltip label="MongoDb">
        <BentoBox size={'C1'} bgImage={'techno/mongodb.svg'} bgSize={'80%'} />
    </Tooltip>
)
export const TechnoMysql = () => (
    <Tooltip label="MySql">
        <BentoBox size={'C1'} bgImage={'techno/mysql.svg'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoCss = () => (
    <Tooltip label="CSS">
        <BentoBox size={'C1'} bgImage={'techno/css.svg'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoArduino = () => (
    <Tooltip label="Arduino">
        <BentoBox size={'C1'} bgImage={'techno/arduino.svg'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoRaspberry = () => (
    <Tooltip label="Raspberry">
        <BentoBox size={'C1'} bgImage={'techno/raspberry.svg'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoSony = () => (
    <Tooltip label="Sony Alpha">
        <BentoBox
            size={'C1'}
            bgImage={'/techno/sonyalpha.svg'}
            bgSize={'70%'}
        />
    </Tooltip>
)
export const TechnoFCPX = () => (
    <Tooltip label="FCPX">
        <BentoBox size={'C1'} bgImage={'techno/fcpx.png'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoMeteor = () => (
    <Tooltip label="Meteor JS">
        <BentoBox size={'C1'} bgImage={'techno/meteor.svg'} bgSize={'70%'} />
    </Tooltip>
)
export const TechnoRabbitMQ = () => (
    <Tooltip label="RabbitMq">
        <BentoBox size={'C1'} bgImage={'techno/rabbitMQ.svg'} bgSize={'60%'} />
    </Tooltip>
)

export const TechnoSpark = () => (
    <Tooltip label="Spark">
        <BentoBox
            size={'C1'}
            bg={'#FFF'}
            bgImage={'techno/spark.svg'}
            bgSize={'80%'}
        />
    </Tooltip>
)

export const TechnoScala = () => (
    <Tooltip label="Scala">
        <BentoBox size={'C1'} bgImage={'techno/scala.svg'} bgSize={'50%'} />
    </Tooltip>
)

export const TechnoUnity = () => (
    <Tooltip label="Unity">
        <BentoBox
            size={'C1'}
            bg={'#DDD'}
            bgImage={'techno/unity.svg'}
            bgSize={'75%'}
        />
    </Tooltip>
)

export const TechnoCSharp = () => (
    <Tooltip label="C#">
        <BentoBox size={'C1'} bgImage={'techno/csharp.svg'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoC = () => (
    <Tooltip label="C">
        <BentoBox size={'C1'} bgImage={'techno/c.svg'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoPhp = () => (
    <Tooltip label="Php">
        <BentoBox size={'C1'} bgImage={'techno/php.svg'} bgSize={'70%'} />
    </Tooltip>
)

export const TechnoKafka = () => (
    <Tooltip label="Apache Kafka">
        <BentoBox size={'C1'} bgImage={'techno/kafka.svg'} bgSize={'45%'} />
    </Tooltip>
)

export const TechnoTerminal = () => (
    <Tooltip label="Gestion système">
        <BentoBox size={'C1'} bgImage={'techno/terminal.svg'} bgSize={'60%'} />
    </Tooltip>
)

export const TechnoLinux = () => (
    <Tooltip label="Linux">
        <BentoBox size={'C1'} bgImage={'techno/linux.svg'} bgSize={'160%'} />
    </Tooltip>
)
