import {
    Box,
    Center,
    Grid,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { languageContext } from '../../LanguageContext/LanguageContext'
import { BentoBox } from '../../Molecule/BentoBox'
import { SectionTitle } from '../../Atom/SectionTitle'
import { TechnoFCPX, TechnoSony } from '../../Molecule/TechnoBox'
import { BluredBox } from '../../Atom/BluredBox'
import {
    ReaseauBPL,
    ReaseauInstagram,
    ReaseauWireStock,
    ReaseauYoutube,
} from '../../Molecule/ReseauBox'
export const AboutMe = () => {
    const { language } = useContext(languageContext)
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box w={'100%'} id="aboutme" pt={'100px'}>
            <SectionTitle withDecoration>
                {language === 'fr' ? <>Qui suis-je ? </> : <>About Me</>}
            </SectionTitle>

            <Center>
                <Grid
                    w={{
                        base: '340px',
                        md: '700px',
                        lg: '1078px',
                    }}
                    templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(8, 1fr)',
                        lg: 'repeat(12, 1fr)',
                    }}
                    gridTemplateRows={{
                        base: 'repeat(24, 70px)',
                        md: 'repeat(12, 70px)',
                        lg: 'repeat(8, 70px)',
                    }}
                    gap={4}
                    color={'#F1F2F3'}
                >
                    {/** Bloc 1  */}
                    <BentoBox
                        size={'C4'}
                        bgImage={'profil2.jpg'}
                        bgSize={'cover'}
                    />
                    <BentoBox size={'C4H8'} bgColor="#ffd166">
                        <BluredBox>
                            <Center>
                                <Text as="b" fontSize={'22px'}>
                                    Voyage
                                </Text>
                            </Center>
                            <Text fontSize={'16px'}>
                                Depuis mon adolescence, la découverte du monde
                                et des cultures m'attire.<br></br>
                                Mais ce que qu'il y a peu de temps que j'ai
                                réelement commencé a voyager, en solo ou a
                                plusieurs, en backpack ou en Van.<br></br>
                                C'est a présent mon leitmotiv, qui est rendu
                                possible grâce à mon travail en freelance, qui
                                n'a pas de frontiere.
                            </Text>

                            <Center>
                                <Text as="b" fontSize={'22px'} mt="20px">
                                    Création de contenu
                                </Text>
                            </Center>
                            <Text fontSize={'16px'}>
                                C'est une autre activité qui me tient a coeur,
                                et qui est en partie lié a mes voyages.<br></br>{' '}
                                Je capture, monte et travail sur divers contenu
                                vidéo ou photographique, que je partage sur mon
                                site personnel dédié ou sur Youtube.<br></br>
                            </Text>
                        </BluredBox>
                    </BentoBox>

                    <BentoBox size={'C2H1'}>
                        <Center as="b" mt={'4px'}>
                            Photographie
                        </Center>
                    </BentoBox>
                    <BentoBox
                        size={'C2'}
                        bg={'blue'}
                        bgImage={'/aboutme/sheetah.jpg'}
                        bgSize={'cover'}
                    />
                    <ReaseauWireStock />
                    <ReaseauInstagram />
                    <BentoBox
                        size={'C2'}
                        bg={'blue'}
                        bgImage={'/aboutme/milan.jpg'}
                        bgSize={'cover'}
                    />
                    <BentoBox
                        size={'C2'}
                        bg={'blue'}
                        bgImage={'/aboutme/night.jpg'}
                        bgSize={'cover'}
                    />

                    <BentoBox
                        size={'C4'}
                        bgImage={'/world.svg'}
                        bgSize={'120%'}
                        onClick={onOpen}
                    />

                    <BentoBox size={'C3H1'}>
                        <Center as="b" mt={'4px'}>
                            Vidéographie
                        </Center>
                    </BentoBox>
                    <ReaseauYoutube />

                    <BentoBox
                        size={'C4H2'}
                        bg={'green'}
                        bgImage={'/aboutme/banner.jpg'}
                        bgSize={'cover'}
                    />

                    <TechnoFCPX />
                    <ReaseauBPL />

                    <TechnoSony />
                </Grid>
            </Center>

            <Modal isOpen={isOpen} onClose={onClose} size={'xxl'}>
                <ModalOverlay />
                <ModalContent bg={'#1F2937'} color={'#F1F2F3'}>
                    <ModalHeader>Pays visités</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image my={'30px'} src={'/world.svg'} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}
