/*
 * Barre de navigation générale du site
 */

import { Box, Flex, Center, useColorMode } from '@chakra-ui/react'
import { languageContext } from '../../LanguageContext/LanguageContext'
import { useContext } from 'react'

interface PropsLink {
    url: string
    name: string
    onSuccess?: () => void
}

const NavButton = (props: PropsLink) => {
    return (
        <Box
            onClick={() => window.location.replace(props.url)}
            color={'white'}
            p={'6px'}
            fontWeight={'bold'}
            w={{ base: '100%', sm: '380px', md: '450px' }}
            fontSize={{ base: '10px', sm: '12px', md: '15px' }}
            _hover={{
                color: '#fe5f55',
                cursor: 'pointer',
            }}
        >
            {props.name}
        </Box>
    )
}

export default function NavBar() {
    const { language, setLanguage } = useContext(languageContext)
    const { toggleColorMode } = useColorMode()
    return (
        <>
            <Center>
                <Box
                    position={'fixed'}
                    bg={'rgba(0,0,0,0.4)'}
                    border={{
                        base: '',
                        sm: 'solid 1px gray',
                    }}
                    borderBottom={{
                        base: 'solid 1px gray ',
                        sm: '',
                    }}
                    w={{ base: '100%', sm: '380px', md: '450px' }}
                    borderRadius={{ base: '0', sm: '20px' }}
                    top={{ base: '0', sm: '10px' }}
                    backdropFilter={'blur(10px)'}
                    px={'5px'}
                    zIndex={'10'}
                >
                    <Flex
                        h={'50px'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <NavButton
                            url="#welcome"
                            name={language === 'fr' ? 'Acceuil' : 'Home'}
                        />
                        <NavButton
                            url="#service"
                            name={language === 'fr' ? 'Service' : 'Offer'}
                        />
                        <NavButton
                            url="#project"
                            name={language === 'fr' ? 'Projets' : 'Projects'}
                        />
                        <NavButton
                            url="#skills"
                            name={language === 'fr' ? 'Competences' : 'Skills'}
                        />
                        <NavButton
                            url="#aboutme"
                            name={language === 'fr' ? 'Moi ?' : 'About me'}
                        />
                        <NavButton
                            url="#contact"
                            name={language === 'fr' ? 'Contact' : 'Contact'}
                        />
                    </Flex>
                </Box>
            </Center>
            {/** 

            <Box
                position={'fixed'}
                right={'10px'}
                visibility={{ base: 'hidden', md: 'visible' }}
                top={'10px'}
                bg={'rgba(0,0,0,0.5)'}
                _hover={{
                    bg: 'rgba(255,255,255,1)',
                    cursor: 'pointer',
                }}
                px={4}
                border={'solid 1px gray'}
                w={'65px'}
                borderRadius={'25px'}
                className="blur"
                fontSize={'30px'}
                zIndex={'10'}
                onClick={() => {
                    if (language === 'fr') {
                        setLanguage('en')
                    } else if (language === 'en') {
                        setLanguage('fr')
                    }
                }}
            >
                <Flex
                    h={'50px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    {language === 'fr' ? <>🇬🇧</> : <>🇫🇷</>}
                </Flex>
            </Box>
            <Box
                position={'fixed'}
                right={'80px'}
                visibility={{ base: 'hidden', md: 'visible' }}
                top={'10px'}
                bg={'rgba(0,0,0,0.5)'}
                _hover={{
                    bg: 'rgba(255,255,255,1)',
                    cursor: 'pointer',
                }}
                px={4}
                border={'solid 1px gray'}
                w={'65px'}
                borderRadius={'25px'}
                className="blur"
                fontSize={'30px'}
                zIndex={'10'}
                onClick={toggleColorMode}
            >
                <Flex
                    h={'50px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    L/D
                </Flex>
            </Box>

            <Box
                position={'fixed'}
                right={'10px'}
                visibility={{ base: 'visible', md: 'hidden' }}
                bottom={'10px'}
                bg={'rgba(0,0,0,0.5)'}
                _hover={{
                    bg: 'rgba(255,255,255,1)',
                    cursor: 'pointer',
                }}
                px={4}
                border={'solid 1px gray'}
                w={'65px'}
                borderRadius={'25px'}
                className="blur"
                fontSize={'30px'}
                zIndex={'10'}
                onClick={() => {
                    if (language === 'fr') {
                        setLanguage('en')
                    } else if (language === 'en') {
                        setLanguage('fr')
                    }
                }}
            >
                <Flex
                    h={'50px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    {language === 'fr' ? <>🇬🇧</> : <>🇫🇷</>}
                </Flex>
            </Box>*/}
        </>
    )
}
