import { createContext, useState, ReactNode } from 'react'

export const languageContext = createContext({
    language: 'fr',
    setLanguage: (value: string) => {},
})

interface Props {
    children: ReactNode
}

export const LanguageContextProvider = (props: Props) => {
    const [language, setLanguage] = useState('fr')

    return (
        <languageContext.Provider value={{ language, setLanguage }}>
            {props.children}
        </languageContext.Provider>
    )
}
