import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {
    ChakraProvider,
    StyleFunctionProps,
    ThemeConfig,
} from '@chakra-ui/react'
import NavBar from './components/Layout/NavBar'
import { MainContent } from './components/Layout/MainContent'
import { LanguageContextProvider } from './components/LanguageContext/LanguageContext'
import { extendTheme } from '@chakra-ui/react'

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
    base: '0px',
    sm: '365px',
    md: '770px',
    lg: '1120px',
}

const config: ThemeConfig = {}
// 3. Extend the theme
const theme = extendTheme({ breakpoints, config })
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <LanguageContextProvider>
                <Router>
                    <App />
                </Router>
            </LanguageContextProvider>
        </ChakraProvider>
    </React.StrictMode>
)

function App() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: 'black',
            }}
        >
            <NavBar />
            <Routes>
                <Route path="*" Component={MainContent} />
            </Routes>
            <div
                style={{
                    marginTop: 'auto',
                }}
            ></div>
        </div>
    )
}
