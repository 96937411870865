import { Center, Tooltip } from '@chakra-ui/react'
import { BentoBox } from '../BentoBox'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'

export const ReaseauWireStock = () => (
    <Tooltip label="Ouvrir WireStock">
        <BentoBox
            size={'C1'}
            bgImage={'/aboutme/wirestock.png'}
            bgSize={'80%'}
            as="a"
            href="https://wirestock.io/quentin.dubois?content=gallery"
            target="_blank"
        />
    </Tooltip>
)

export const ReaseauInstagram = () => (
    <Tooltip label="Ouvrir Instagram">
        <BentoBox
            size={'C1'}
            bgImage={'reseau/instagram.svg'}
            bgSize={'80%'}
            as="a"
            href="https://instagram.com/frenchoute"
            target="_blank"
        />
    </Tooltip>
)

export const ReaseauYoutube = () => (
    <Tooltip label="Ouvrir YouTube">
        <BentoBox
            size={'C1'}
            bgImage={'/reseau/youtubemini.svg'}
            bgSize={'80%'}
            as="a"
            href="https://www.youtube.com/@frenchoute"
            target="_blank"
        />
    </Tooltip>
)

export const ReaseauMalt = () => (
    <Tooltip label="Ouvrir Malt">
        <BentoBox
            size={'C1'}
            bgImage={'reseau/malt.svg'}
            bgSize={'80%'}
            as="a"
            href="https://www.malt.fr/profile/qdub"
            target="_blank"
        />
    </Tooltip>
)

export const ReaseauLK = () => (
    <Tooltip label="Ouvrir LinkedIn">
        <BentoBox
            size={'C1'}
            bgColor={'#0076b2'}
            bgImage={'reseau/LinkedIn.svg'}
            bgSize={'80%'}
            as="a"
            href="https://www.linkedin.com/in/qdub/"
            target="_blank"
        />
    </Tooltip>
)

export const ReseauMail = () => (
    <Tooltip label="Mail : duboisq.pro@gmail.com">
        <BentoBox
            as="a"
            size={'C1'}
            href={
                "mailto:duboisq.pro@gmail.com?body=Bonjour! J'aimerais faire appel a vos services..."
            }
        >
            <EmailIcon fontSize={'32px'}></EmailIcon>
        </BentoBox>
    </Tooltip>
)

export const ReseauTel = () => (
    <Tooltip label="Téléphone : +33 6 68 34 39 86">
        <BentoBox
            as="a"
            size={'C1'}
            href={
                "sms:+33668343986?body=Bonjour! J'aimerais faire appel a vos services..."
            }
        >
            <PhoneIcon fontSize={'32px'}></PhoneIcon>
        </BentoBox>
    </Tooltip>
)

export const ReaseauBPL = () => (
    <Tooltip label="Ouvrir BackPackLife.fr">
        <BentoBox
            size={'C2H1'}
            bgImage={'reseau/bpl.png'}
            bgSize={'90%'}
            as="a"
            href="https://www.backpacklife.fr"
            target="_blank"
        />
    </Tooltip>
)
