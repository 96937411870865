import { Box, Center, useColorModeValue } from '@chakra-ui/react'

export const Footer = () => {
    return (
        <Box
            mt={'50px'}
            w={'100%'}
            bg={useColorModeValue('#1F2937', '#EEEEEE')}
            color={useColorModeValue('#FFF', '#000')}
            id="reseauxsociaux"
            p={'20px'}
        >
            <Center>2024 © Quentin Dubois. Tous droits réservés.</Center>
        </Box>
    )
}
