import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useColorModeValue,
    Grid,
    Center,
    Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

type Props = {
    title: string
    entreprise: string
    duree: string
    content: string
    isOpen: boolean
    onClose: () => void
    children?: ReactNode
}

export const ModalProjet = (props: Props) => {
    const bgColorBase = useColorModeValue('#1F2937', '#EEEEEE')
    const colorBase = useColorModeValue('#FFF', '#000')

    return (
        <>
            <Modal isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay backdropFilter={'blur(5px)'} />
                <ModalContent
                    bg={bgColorBase}
                    color={colorBase}
                    borderRadius={'20px'}
                >
                    <ModalHeader>{props.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={'20px'}>
                        <Text pb={'5px'}>
                            <b>Entreprise :</b> {props.entreprise}
                        </Text>
                        <Text pb={'10px'}>
                            <b>Durée : </b> {props.duree}
                        </Text>
                        <Text pb={'20px'} fontSize={'14px'}>
                            {
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.content,
                                    }}
                                ></div>
                            }
                        </Text>
                        {props.children && (
                            <>
                                <Center pb={'10px'}>
                                    <Text>Technologies principales</Text>
                                </Center>
                                <Center>
                                    <Grid
                                        w={'350px'}
                                        templateColumns={'repeat(6, 1fr)'}
                                        gridTemplateRows={'repeat(1, 50px)'}
                                        gap={2}
                                    >
                                        {props.children}
                                    </Grid>
                                </Center>
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
