import { Box, Center, Grid, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { languageContext } from '../../LanguageContext/LanguageContext'
import { BentoBox } from '../../Molecule/BentoBox'
import { SectionTitle } from '../../Atom/SectionTitle'
import { BluredBox } from '../../Atom/BluredBox'

export const Service = () => {
    const { language } = useContext(languageContext)

    return (
        <Box w={'100%'} id="service" pt={'100px'}>
            <SectionTitle withDecoration>
                {language === 'fr' ? <>Mes Services</> : <>My Offers</>}
            </SectionTitle>

            <Center>
                <Grid
                    w={{
                        base: '340px',
                        md: '700px',
                        lg: '1078px',
                    }}
                    templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(8, 1fr)',
                        lg: 'repeat(12, 1fr)',
                    }}
                    gridTemplateRows={{
                        base: 'repeat(18, 70px)',
                        md: 'repeat(10, 70px)',
                        lg: 'repeat(6, 70px)',
                    }}
                    gap={4}
                    color={'#F1F2F3'}
                >
                    {/** Bloc 1  */}
                    <BentoBox size={'C4H6'} bgColor="#06d6a0">
                        <BluredBox>
                            <Center>
                                <Text
                                    as="b"
                                    fontSize={'22px'}
                                    textAlign={'center'}
                                >
                                    Conception de solutions informatiques
                                </Text>
                            </Center>
                            <Text fontSize={'16px'}>
                                Que vous soyez un entrepreneur solitaire, une{' '}
                                <b>StartUp</b> ou une entreprise solidement
                                établie, je vous accompagne dans la réalisation
                                technique d'outils dédiés pour votre business.{' '}
                                <br></br> Afin de mener au mieu cette mission,
                                j'interviens lors de différentes étapes:
                                <br></br>- Receuil de l'idée et des besoins
                                <br></br>- Proposition technique adaptée
                                <br></br>- Développement de la solution
                                <br></br>- Déploiement & livraison
                                <br></br>- Maintenance (TMA)
                                <br></br>- Évolution
                            </Text>
                        </BluredBox>
                    </BentoBox>
                    <BentoBox size={'C4'}>
                        <Center>
                            <Text as="b" fontSize={'22px'}>
                                Concrètement
                            </Text>
                        </Center>
                        <Text
                            fontSize={'16px'}
                            textAlign={'center'}
                            mt={'15px'}
                        >
                            Je peux travailler sur différents types de projets.
                            <br></br>
                            De la réalisation web standard sur vos propre
                            serveur aux application métiers avancées hébergées
                            dans le cloud.<br></br>
                            <br></br> Si vos besoins sont plus importants, je
                            peux aussi m'intégrer a vos équipes et travailler de
                            manière plus localisé.
                        </Text>
                    </BentoBox>
                    <BentoBox
                        size="C2"
                        bgImage={'/offers/idea.jpg'}
                        bgSize={'cover'}
                    />
                    <BentoBox size="C2">
                        <Center
                            as="b"
                            mt={'28px'}
                            fontSize={'20px'}
                            textAlign={'center'}
                        >
                            Idée & Brainstorming
                        </Center>
                    </BentoBox>
                    <BentoBox size="C2">
                        <Center
                            as="b"
                            mt={'8px'}
                            fontSize={'20px'}
                            textAlign={'center'}
                        >
                            Spécification & proposition technique
                        </Center>
                    </BentoBox>
                    <BentoBox
                        size="C2"
                        bgImage={'/offers/brainstorming.jpg'}
                        bgSize={'cover'}
                    />

                    <BentoBox
                        size="C4H2"
                        bgImage={'/offers/code.jpg'}
                        bgSize={'cover'}
                    />
                    <BentoBox size="C4H2">
                        <Center
                            as="b"
                            mt={'15px'}
                            fontSize={'25px'}
                            textAlign={'center'}
                        >
                            Développement & livraison de la solution
                        </Center>
                    </BentoBox>
                </Grid>
            </Center>
        </Box>
    )
}
