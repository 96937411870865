import {
    Box,
    Center,
    Heading,
    Grid,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { languageContext } from '../../LanguageContext/LanguageContext'
import { BentoBox } from '../../Molecule/BentoBox'
import { SectionTitle } from '../../Atom/SectionTitle'
import {
    TechnoGIT,
    TechnoServerLess,
    TechnoWP,
    TechnoGCP,
    TechnoFireBase,
    TechnoDocker,
    TechnoJS,
    TechnoNode,
    TechnoTS,
    TechnoReact,
    TechnoAPI,
    TechnoJava,
    TechnoMongoDb,
    TechnoMysql,
    TechnoTerminal,
    TechnoLinux,
} from '../../Molecule/TechnoBox'
import { BluredBox } from '../../Atom/BluredBox'
export const Skills = () => {
    const { language } = useContext(languageContext)

    return (
        <Box w={'100%'} id="skills" pt={'100px'}>
            <SectionTitle withDecoration>
                {language === 'fr' ? <>Compétences Techniques</> : <>Skills</>}
            </SectionTitle>

            <Center>
                <Grid
                    w={{
                        base: '340px',
                        md: '700px',
                        lg: '1078px',
                    }}
                    templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(8, 1fr)',
                        lg: 'repeat(12, 1fr)',
                    }}
                    gridTemplateRows={{
                        base: 'repeat(24, 70px)',
                        md: 'repeat(12, 70px)',
                        lg: 'repeat(8, 70px)',
                    }}
                    gap={4}
                    color={'#F1F2F3'}
                >
                    {/** Bloc 3  */}
                    <BentoBox size={'C4H8'} bgColor="#118ab2">
                        <BluredBox>
                            <Center>
                                <Text as="b" fontSize={'22px'}>
                                    Développement FullStack
                                </Text>
                            </Center>
                            <Text fontSize={'16px'} mt={'15px'}>
                                Je peux concevoir une architecture technique a
                                partir d'un besoin métier.<br></br>
                                <br></br>
                                Je suis apte a travailler techniquement sur
                                toute la chaine de data d'une application, que
                                ce soit coté stockage, traitement ou affichage.
                                <br></br>
                                <br></br>
                                En parralèle de la conception et du
                                développement purement technique, je maitrise
                                les outils principaux permettant le déploiement
                                automatisé, la maintenance et la gestion
                                d'infrastructure On Premise ou Cloud.<br></br>
                                <br></br>
                            </Text>
                        </BluredBox>
                    </BentoBox>
                    <BentoBox size={'C4'}>
                        <Center>
                            <Text as="b" fontSize={'22px'}>
                                Développement FullStack
                            </Text>
                        </Center>
                        <Text fontSize={'16px'} mt={'15px'}>
                            J'ai une forte appétence envers les technologies
                            FrontEnd web, en particulier ReactJS.<br></br> Je
                            réalise aussi les parties BackEnd, API, traitement
                            des données, principalement en Java.<br></br>
                            <br></br> Gestion de la données au travers de BDD
                            relationnel ou orienté document.
                        </Text>
                    </BentoBox>
                    {/** FRONT */}
                    <BentoBox size={'C2'}>
                        <Center as="b" mt={'15px'} fontSize={'25px'}>
                            WEB
                        </Center>
                        <Center as="b" mt={'15px'} fontSize={'20px'}>
                            Front End
                        </Center>
                    </BentoBox>

                    <TechnoReact />
                    <TechnoTS />
                    <TechnoNode />
                    <TechnoJS />

                    {/** BACKEND */}
                    <TechnoJava />
                    <TechnoAPI />

                    <BentoBox size={'C2'}>
                        <Center as="b" mt={'35px'} fontSize={'25px'}>
                            BackEnd
                        </Center>
                    </BentoBox>
                    <TechnoMongoDb />
                    <TechnoMysql />

                    <BentoBox size={'C4'}>
                        <Center>
                            <Text as="b" fontSize={'22px'}>
                                Cloud
                            </Text>
                        </Center>
                        <Text
                            fontSize={'16px'}
                            textAlign={'center'}
                            mt={'25px'}
                        >
                            Dans une optique de croissance de votre business, je
                            suis capable de concevoir et déployer des
                            applications entiérement dans le Cloud offrant une
                            flexibilité avancé en terme de gestion des coût,
                            facilité de maintenance et mise a l'échelle.
                        </Text>
                    </BentoBox>
                    {/** DEVOPS */}
                    <BentoBox size={'C2'}>
                        <Center as="b" mt={'15px'} fontSize={'25px'}>
                            Cloud
                        </Center>
                        <Center as="b" mt={'15px'} fontSize={'20px'}>
                            DevOps
                        </Center>
                    </BentoBox>
                    <TechnoGCP />
                    <TechnoFireBase />
                    <TechnoDocker />
                    <TechnoServerLess />
                    {/** BDD */}
                    <TechnoWP />
                    <TechnoTerminal />
                    <BentoBox size={'C2H1'}>
                        <Center as="b" mt={'-4px'} fontSize={'25px'}>
                            Outils
                        </Center>
                    </BentoBox>
                    <BentoBox size={'C2H1'}>
                        <Center as="b" mt={'-2px'} fontSize={'20px'}>
                            CI / CD
                        </Center>
                    </BentoBox>
                    <TechnoGIT />
                    <TechnoLinux />
                </Grid>
            </Center>
        </Box>
    )
}
