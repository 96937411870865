import {
    GridItem,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useColorModeValue,
    GridItemProps,
    forwardRef,
    Grid,
    Center,
    Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

type Props = {
    size:
        | 'C1'
        | 'C1H2'
        | 'C2H1'
        | 'C2'
        | 'C2H4'
        | 'C3H1'
        | 'C4H2'
        | 'C4'
        | 'C4H6'
        | 'C4H8'
    bgColor?: string
    bgImageUrl?: string
    bgImageSize?: string
    children?: ReactNode
    withModal?: boolean
    onClicAction?: () => void
} & GridItemProps

export const BentoBox = forwardRef<Props, 'div'>(
    (
        {
            size,
            bgColor,
            bgImageUrl,
            bgImageSize,
            children,
            onClicAction,
            ...props
        },
        ref
    ) => {
        let sizeX = 0
        let sizeY = 0
        switch (size) {
            case 'C1':
                sizeX = 1
                sizeY = 1
                break
            case 'C1H2':
                sizeX = 1
                sizeY = 2
                break
            case 'C2H1':
                sizeX = 2
                sizeY = 1
                break
            case 'C2':
                sizeX = 2
                sizeY = 2
                break
            case 'C2H4':
                sizeX = 2
                sizeY = 4
                break
            case 'C3H1':
                sizeX = 3
                sizeY = 1
                break
            case 'C4H2':
                sizeX = 4
                sizeY = 2
                break
            case 'C4':
                sizeX = 4
                sizeY = 4
                break
            case 'C4H6':
                sizeX = 4
                sizeY = 6
                break
            case 'C4H8':
                sizeX = 4
                sizeY = 8
                break
        }

        const bgColorBase = useColorModeValue('#1F2937', '#EEEEEE')
        const colorBase = useColorModeValue('#FFF', '#000')
        return (
            <>
                <GridItem
                    ref={ref}
                    onClick={onClicAction}
                    rowSpan={sizeY}
                    colSpan={sizeX}
                    borderRadius={'20px'}
                    bg={bgColor ? bgColor : bgColorBase}
                    color={colorBase}
                    padding={'20px'}
                    backgroundPosition={'center'}
                    backgroundRepeat={'no-repeat'}
                    transition={'transform .2s ,box-shadow 0.2s ease-in-out;'}
                    overflow={'hidden'}
                    position="relative"
                    _hover={{
                        transform: 'scale(1.02)',
                        boxShadow:
                            'rgba(255,255, 255, 0.1) 0px 0px 8px, rgba(255,255, 255, 0.1) 0px 0px 12px, rgba(255,255, 255, 0.1) 0px 0px 28px',
                    }}
                    {...props}
                >
                    {children}
                </GridItem>
            </>
        )
    }
)
