import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface Props {
    children: ReactNode
}

export const BottomBox = (props: Props) => {
    return (
        <Box
            bg={'#FFFFFF99'}
            margin={'-20px'}
            position={'absolute'}
            width={'100%'}
            bottom={'20px'}
            height={'80px'}
            color={'black'}
            padding={'20px'}
            backdropFilter={'blur(10px)'}
        >
            {props.children}
        </Box>
    )
}
