import { Box, useColorModeValue } from '@chakra-ui/react'

import { Footer } from '../Footer'
import { Welcome } from '../../Organism/Welcome'
import { Service } from '../../Organism/Service'
import { Project } from '../../Organism/Project'
import { Skills } from '../../Organism/Skills'
import { AboutMe } from '../../Organism/AboutMe'
import { Contact } from '../../Organism/Contact'

export const MainContent = () => {
    return (
        <Box w={'100%'} bg={useColorModeValue('#111827', '#FFFFFF')}>
            <Welcome />
            <Service />
            <Project />
            <Skills />
            <AboutMe />
            <Contact />
            <Footer />
        </Box>
    )
}
